import styled from "styled-components";
import { devices } from "./devices";

export const H = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 4.5vw;
  font-weight: 900;
  text-align: left;
  line-height: 1.2;
  color: #340020;

  @media ${devices.mobile} {
    font-size: 9.5vw;
  }
`;

export const P = styled.h3`
  font-family: "Roboto", sans-serif;
  font-size: 1.3vw;
  font-weight: 300;
  text-align: left;
  line-height: 1.5;
  color: #340020;

  @media ${devices.mobile} {
    font-size: 5vw;
  }
`;
