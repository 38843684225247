import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { H, P } from "../../theme/Styles";

const Container = styled.div`
  width: 80vw;
  height: 75vw;
  position: relative;
  /* display: flex; */
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  @media ${devices.mobile} {
    width: 100vw;
    height: auto;
  }
`;

const ProjectImage = styled.img<{ offset?: number }>`
  width: 80vw;
  height: auto;
  @media ${devices.mobile} {
    width: 200vw;
    margin-left: ${(props) => -1 * (props.offset ? props.offset : 30)}vw;
  }
`;

const TextBox = styled.div<{ color: string; position: number }>`
  width: 25vw;
  height: 28vw;
  background-color: ${(props) => props.color};
  position: relative;
  left: ${(props) => (props.position > 2 ? 45 : 8)}vw;
  top: ${(props) => (props.position % 2 == 1 ? -60 : -18)}vw;
  padding: 3vw;

  @media ${devices.mobile} {
    width: 80vw;
    height: 28vw;
    left: 5vw;
    padding: 5vw;
    height: auto;
  }
`;

const Title = styled(H)`
  font-size: 2.5vw;
  margin: 0;
  @media ${devices.mobile} {
    font-size: 8vw;
  }
`;

const Description = styled(P)`
  font-size: 1.2vw;
  @media ${devices.mobile} {
    font-size: 5vw;
  }
`;

interface ProjectProps {
  imageSrc: string;
  color: string;
  position?: number;
  title: string;
  description: string;
  imageOffset?: number;
}

export const TextPositions = {
  TOP_LEFT: 1,
  BOTTOM_LEFT: 2,
  TOP_RIGHT: 3,
  BOTTOM_RIGHT: 4,
};

export default function Project(props: ProjectProps) {
  return (
    <Container>
      <ProjectImage
        offset={props.imageOffset}
        src={props.imageSrc}
      ></ProjectImage>
      <TextBox
        color={props.color}
        position={props.position || TextPositions.BOTTOM_LEFT}
      >
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </TextBox>
    </Container>
  );
}
