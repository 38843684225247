import React from "react";
import styled from "styled-components";
import { H, P } from "../../theme/Styles";
import Profile from "./Profile";
import PiyumImage from "./assets/piyum.png";
import NipuniImage from "./assets/nipuni.png";
import { devices } from "../../theme/devices";

const Container = styled.div`
  padding-top: 5vw;
  background-color: #ffffff;
`;

const TextContainer = styled.div`
  width: 80vw;
  margin: auto;
`;

const ProfileContainer = styled.div`
  width: 80vw;
  margin: 10vw auto;
  display: flex;
  justify-content: space-between;

  @media ${devices.mobile} {
    flex-direction: column;
    width: 100vw;
  }
`;

export default function ProfilesSection() {
  return (
    <Container>
      <TextContainer>
        <H>Who we are</H>
      </TextContainer>
      <ProfileContainer>
        <Profile
          imageSrc={NipuniImage}
          name={"Nipuni Siyambalapitiya"}
          title={"Co-founder and Design Lead"}
          description={
            "An industrial designer by training, Nipuni seeks to create inclusive and sustainable products and systems. She completed her Masters in Industrial Design in Arizona State University in May 2020. She has designed and shipped a variety of medical products and household goods since earning her Bachelor’s degree in design from University of Moratuwa in 2015. She leads XD’s branding, visual design and industrial design collaborations while also co-teaching an Industrial Design studio at Arizona State University."
          }
        ></Profile>
        <Profile
          imageSrc={PiyumImage}
          name={"Piyum Fernando, PhD"}
          title={"Co-founder and Research Lead"}
          description={
            "A designer at heart, an engineer by trade, Piyum’s work strives to bridge people and technology in meaningful ways to create delightful digital experiences. He earned his PhD in Human Computer Interaction from Arizona State University in December 2020. Prior to that, he worked as a researcher and a software engineer in Singapore and Sri Lanka. He holds a Bachelor’s degree in Computer Science and Engineering from University of Moratuwa, Sri Lanka. He heads XD’s UX research and design, software development and hardware prototyping efforts. "
          }
        ></Profile>
      </ProfileContainer>
    </Container>
  );
}
