import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import logo from "./assets/logo.svg";

const Container = styled.div`
  position: absolute;
  top: 3vw;
  left: 5vw;
`;

const Logo = styled.img`
  width: 5vw;
  height: auto;
  @media ${devices.mobile} {
    width: 18vw;
  }
`;

export default function Brand() {
  return (
    <Container>
      <Logo src={logo}></Logo>
    </Container>
  );
}
