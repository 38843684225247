import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Landing from "./components/landing/Landing";
import Brand from "./components/brand/Brand";
import WorkSection from "./components/work/WorkSection";
import ProfilesSection from "./components/profiles/ProfilesSection";

function App() {
  return (
    <div>
      <Brand></Brand>
      <Landing></Landing>
      <WorkSection></WorkSection>
      <ProfilesSection></ProfilesSection>
    </div>
  );
}

export default App;
