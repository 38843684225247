import React from "react";
import Project, { TextPositions } from "./Project";
import project1Image from "./assets/project1_banner.jpg";
import sparkImage from "./assets/spark.png";
import wizz from "./assets/wizz.jpg";
import styled from "styled-components";
import { H, P } from "../../theme/Styles";
import { devices } from "../../theme/devices";

const Container = styled.div`
  padding-top: 5vw;
  background-color: #f4f4f4;
`;

const TextContainer = styled.div`
  width: 80vw;
  margin: auto;
`;

export default function WorkSection() {
  return (
    <Container>
      <TextContainer>
        <H>Our Work</H>
      </TextContainer>

      <Project
        imageSrc={project1Image}
        color={"#ffffff"}
        title={"Developing a design strategy for Phoenix public transportation"}
        position={TextPositions.BOTTOM_RIGHT}
        description={
          "The population of Phoenix, Arizona is booming. The city welcomes new residents each year at a rate higher than any other city in the US.  How can we make Phoenix public transport attractive to this growing populace? Through a 3-week long design sprint, we developed strategic directions to unify the payment experience, introduce commuter friendly services and implement effective communication campaigns."
        }
      ></Project>
      <Project
        imageSrc={sparkImage}
        imageOffset={10}
        color={"#ffffff"}
        title={"Helping a Sri Lankan startup to re-think IT e-learning"}
        position={TextPositions.BOTTOM_LEFT}
        description={
          "Sri Lanka has a world renowned IT industry. It continues to provide high quality software applications and IT services to clients across the world. To support the steady growth of the industry, Sri Lanka needs to increase its production of high quality  fresh IT professionals.  We helped a Sri Lankan startup to build a business around this need."
        }
      ></Project>
      <Project
        imageSrc={wizz}
        imageOffset={70}
        color={"#ffffff"}
        title={"Designing an accessible mobile app for English learning"}
        position={TextPositions.BOTTOM_LEFT}
        description={
          "For more than 90% of Sri Lankans English is not their first language. Yet, it is a vital skill to progress in almost any career. Across the country, there are significant barriers to access affordable and effective English learning. In collaboration with a young EdTech startup, we reimagined the learning and teaching of career-focused English skills through accessible mobile platforms."
        }
      ></Project>
    </Container>
  );
}
