import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { H, P } from "../../theme/Styles";

const Container = styled.div`
  width: 35vw;
  height: 50vw;
  position: relative;
  @media ${devices.mobile} {
    width: 90vw;
    height: auto;
    margin: auto;
  }
`;

const TextContainer = styled.div`
  width: 28vw;
  height: 28vw;
  margin-top: 10vw;
  background-color: #e9ec46;
  padding: 4vw;
  padding-top: 12vw;
  @media ${devices.mobile} {
    width: 73vw;
    height: auto;
    padding: 8vw;
    padding-top: 40vw;
    margin-top: 20vw;
    margin-bottom: 20vw;
  }
`;

const Name = styled(H)`
  font-size: 2vw;
  margin: 0;
  @media ${devices.mobile} {
    font-size: 6.5vw;
  }
`;

const Title = styled(H)`
  font-size: 1.3vw;
  font-weight: 300;
  margin: 0;
  margin-top: 0.5vw;
  @media ${devices.mobile} {
    font-size: 5vw;
    margin-top: 1vw;
  }
`;

const Description = styled(P)`
  font-size: 1.2vw;
  margin-top: 2vw;
  @media ${devices.mobile} {
    font-size: 5vw;
    margin-top: 6vw;
  }
`;

const Image = styled.img`
  width: 20vw;
  height: 20vw;
  position: absolute;
  left: 14vw;

  @media ${devices.mobile} {
    width: 50vw;
    height: 50vw;
    position: absolute;
    left: 19vw;
  }
`;

interface ProfileProps {
  imageSrc: string;
  name: string;
  title: string;
  description: string;
}

export default function Profile(props: ProfileProps) {
  return (
    <Container>
      <Image src={props.imageSrc}></Image>
      <TextContainer>
        <Name>{props.name}</Name>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </TextContainer>
    </Container>
  );
}
