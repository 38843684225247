import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { H, P } from "../../theme/Styles";
import logo from "./assets/hero.svg";

const Container = styled.div`
  width: 100vw;
  min-height: 50vw;
  /* background-color: #f3fffe; */
  display: flex;
  align-items: center;
  overflow: hidden;
  @media ${devices.mobile} {
    align-items: left;
    margin-top: 10vw;
    margin-bottom: 15vw;
    flex-direction: column-reverse;
  }
`;

const TextContainer = styled.div`
  width: 50vw;
  margin: auto;
  @media ${devices.mobile} {
    width: 80vw;
    margin-left: 5vw;
    margin-top: -25vw;
  }
`;

const HeroText = styled(H)`
  @media ${devices.mobile} {
    font-size: 12vw;
  }
`;

const HeroImageContainer = styled.div`
  width: 30vw;
  @media ${devices.mobile} {
    width: 100vw;
  }
`;

const HeroImage = styled.img`
  width: auto;
  max-height: 90vh;
  margin: auto;
  @media ${devices.mobile} {
    width: 125vw;
    max-height: 85vw;
    margin: auto;
    transform: rotate(-20deg);
  }
`;

export default function Landing() {
  return (
    <Container>
      <TextContainer>
        <HeroText>We are an experience design consultancy</HeroText>
        <P>
          We believe that great user experiences are a product of doing many
          things right. <br />
          From getting to know the people to delivering a product that they
          love, <br />
          we help you tick all the boxes!
        </P>
      </TextContainer>
      <HeroImageContainer>
        <HeroImage src={logo}></HeroImage>
      </HeroImageContainer>
    </Container>
  );
}
